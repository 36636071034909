import React, { useEffect, useState } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import WriteButton from './WriteButton';
import {withAppContext} from './AppContext';
import {
  TelegramShareButton,
  TelegramIcon,
  VKShareButton,
  VKIcon,
  VKShareCount,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";
import { Link } from "react-router-dom";

const url = 'https://fitilapp.ru',
  quotes = [
  { 'quote': 'Неуверенность в себе является частью творческого процесса. Это неприятно, но важно понимать, что это часть процесса. В противном случае вы станете самодовольны, что опасно для креативности.' },
  { 'quote': 'Единственный способ найти свой голос — использовать его. Это запрограммировано, встроено в вас. Просто доверяйте себе. Говорите о вещах, которые вы любите, продолжайте изучать то, что вам интересно. И ваш голос будет звучать уверенно.' },
  { 'quote': 'Начните сейчас, работайте каждый день и вы реализуете свою идею. Вы будете ошеломлены тем, сколько страниц можно добавить за очень короткое время и как продвигают проект полчаса работы в день.' },
  { 'quote': 'Писатель — это человек, которому писать сложнее, чем другим людям', 'author': 'Томас Манн'},
  { 'quote': 'Начинайте писать, несмотря ни на что. Вода не течет, пока кран не повернут.' },
  { 'quote': 'Пиши пьяным, редактируй трезвым', 'author': 'Эрнест Хемингуэй' }
];

const Failure = ({onReset, toggleHelp, limit, type, lost, words }) => {
  const [ first, setFirst ] = useState( true );
  const quote = quotes[Math.floor(Math.random() * quotes.length)];

  useEffect( () => {
    var current = JSON.parse(localStorage.getItem('first'));
    if( current === null && lost ) {
      localStorage.setItem('first', false);
    } else {
      setFirst( current === null ? true : current );
    }
  }, [ lost ]);

  return (
    <CSSTransitionGroup
      transitionName="failure"
      transitionEnterTimeout={500}
      transitionLeaveTimeout={100}
    >
    { lost && (
      <div className='failure' key="failScreen">
        <Link to="/help" className="navButton helpButton white">Помощь</Link>
        <div className="inner">
          <h3>Потрачено</h3>
          <p className="text-center white-text">Присоединяйся к сообществу тех, кто ежедневно улучшает свою жизнь через написание текстов – <a href="https://t.me/+Oq417tnZwiFiZmE6" target="_blank" rel="noopener noreferrer">«Фитиль» в Telegram</a></p>
          { first ? <React.Fragment>
            <p className="text-center white-text">Сохрани «Фитиль» себе или отправь другу:</p>
            <div className="flex"> 
              <div className="item">
                <TelegramShareButton
                  url={url}
                  className="item__share-button"
                >
                  <TelegramIcon size={ 48 } round />
                </TelegramShareButton>

                <div className="item__share-count">&nbsp;</div>
              </div>

              <div className="item">
                <WhatsappShareButton
                  url={url}
                  separator=":: "
                >
                  <WhatsappIcon size={ 48 } round />
                </WhatsappShareButton>
              </div>

              <div className="item">
                <VKShareButton
                  url={url}
                >
                  <VKIcon size={ 48 } round />
                </VKShareButton>

                <div className="item__share-count"><VKShareCount /></div>
              </div>
            </div>
          </React.Fragment>
          :
            <div>
              <p className="text-center white-text">«{ quote.quote }»</p>
              { quote.author && <p className="text-center white-text m-0">{ quote.author }</p> }
            </div>
          }
          <WriteButton
            ghost
            noPanel
            color="white"
            label="Попробовать снова"
            type={type}
            limit={limit}
          />
        </div>
      </div>
      )}
    </CSSTransitionGroup>
  )
}

export default withAppContext(Failure);
