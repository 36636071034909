import React, { useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ym from 'react-yandex-metrika';

export default function CopyButton( { text } ) {

  const [ opacity, setOpacity ] = useState( 0 );

  return (
    <div className="copyButton">
      <div className="session-chooser copy" style={{ opacity: opacity }}>
        <div className="compact">
          Cкопировано успешно
        </div>
      </div>
      <CopyToClipboard text={ text } onCopy={() => {
        setOpacity(1);
        setTimeout( () => setOpacity(0), 1000 );
        ym('reachGoal', 'Copy');
      }}>
        <button className="small ghost">Скопировать</button>
      </CopyToClipboard>
    </div>
  );
};

