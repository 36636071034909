import React from "react";
import Space from "./Space";
import { Link } from "react-router-dom";

export default function Help() {
  
  return (
    <div className="Help">
      <Link to="/" className="navButton backButton">
        Назад
      </Link>
      <Space l />
      <div className="content">
        <h1>Помощь</h1>
        <h2>Кто создал это?</h2>
        <p>В основу приложения «Фитиль» положен иностранный <a href="https://github.com/maebert/themostdangerouswritingapp" target="_blank" rel="noopener noreferrer">open source проект</a>, написанный Мануэлем Эбертом несколько лет назад.</p>
        <p>Развивают «Фитиль»: Дмитрий Беговатов и Дмитрий Райз (продукт и маркетинг), Тимофей Хотьков (разработка).</p>
        <p>За идею названия спасибо Евгению Должкевичу.</p>

        <h2>Может ли кто-нибудь прочитать то, что я пишу?</h2>
        <p>Нет, все написанное вами конфиденциально и не передается и не хранится на каком-либо сервере.</p>

        <h2>Как с вами связаться?</h2>
        <p>Если вы хотите задать вопрос, поделиться мнением или предложить свою помощь, вступайте в <a href="https://t.me/+Oq417tnZwiFiZmE6" target="_blank" rel="noopener noreferrer">группу</a> приложения в телеграм – и подписывайтесь на <a href="https://t.me/fitilapp" target="_blank" rel="noopener noreferrer">канал</a> с новостями приложения.</p>

        <Space l />
      </div>
    </div>
  );
};

