import React from 'react';
import WriteButton from './WriteButton';
import Space from './Space';
import Lottie from 'react-lottie';
import { Link } from "react-router-dom";

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: require('../images/fire.json'),
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const Welcome = () =>
  <div className="Welcome">
    <Link to="/help" className="navButton helpButton">Помощь</Link>
    <Space m />
    <div>
      <div className="logo">
        <div className="mark">
          <Lottie
            options={defaultOptions}
            height={ 150 }
            width={ 150 }
          />
        </div>
        <div className="title">
          <div>
            <h1>Фитиль</h1>
            <h2>Самое коварное приложение для написания текстов</h2>
          </div>
        </div>
      </div>
      <div className="description text-left">
        <h3>① Возьми одну идею для поста, заметки или шутки.</h3>
        <h3>② Нажми на кнопку «Начать писать» и пиши всё, что приходит в голову по этой теме.</h3>
        <h3>③ Только не останавливайся! Если ты перестанешь печатать хотя бы на 5 секунд – <span className="noWrap">весь текст «сгорит»<i className="caret icon-cursor"/></span></h3>
      </div>
      <Space l />
      <WriteButton color="red" />
      <Space m />
      <div className="footer">
        <a href="https://t.me/+Oq417tnZwiFiZmE6" target="_blank" rel="noopener noreferrer">Сообщество</a>
        <a href="https://t.me/fitilapp" target="_blank" rel="noopener noreferrer">Новости</a>
      </div>
      <Space m />
    </div>
  </div>


export default Welcome;
